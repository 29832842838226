import { so, design, position } from './gossip'
export default {
  nodes: {},
  edges: {},
  people: {},
  relationships: {}

}

const info = [
  ['Clarrie', 'Family'],
  ['Peg', 'Family'],
  ['Irene', 'Family'],
  ['Irma', 'Family'],
  ['Tristan', 'Family'],
  ['Venice', 'Family'],
  ['David', 'Family'],
  ['Richard', 'Family'],
  ['Peggy', 'Other'],
  ['Eva', 'Other'],
  ['Vince', 'Other'],
  ['Ly', 'Other'],
  ['Albert', 'Other'],
  ['Sailor', 'Unseen'],
  ['Alex', 'Unseen']
].forEach(design)
console.log(info)

const facts = [
  '@Clarrie is the head of the family',
  '@Eva speaks poor @French',
  '@Tristan speaks poor @French',
  '@Eva and @Tristan love each other',
  '@Tristan is jealous of the @Sailor',
  'The @NaughtySong was taught to @Eva by the @Sailor',
  '@Albert and @Peggy are married',
  '@Albert is the jealous type',
  '@Clarrie bought the @LandDownTheHill',
  '@LandDownTheHill was bought before @Albert and @Peggy could afford to buy it',
  '@TheSheltons are a big family, including @Clarrie, @Peg, @Tristan, @Irma, @Irene and @David',
  '@Clarrie is the father of @Irene, @Irma, and @Tristan',
  '@Clarrie is a radio astronomer',
  '@Peg is the wife of @Clarrie',
  '@Peg is a lawyer',
  '@Irene is the eldest daughter, separated from her husband',
  '@Irene is the mother of @Venice'
].forEach(so)
position()
console.log(facts)
