<template>
  <div class='home'>
    <v-network-graph
      class='graph'
      :nodes='nodes'
      :edges='edges'
      :event-handlers='eventHandlers'
      :layouts='layouts'
      :configs='configs'
    />
    <div>
      <h1>{{ selected.name }}</h1>
      <ul>
        <li v-for="(fact, idx) in selected.facts" :key="`fact-${idx}`" v-html="buildText(fact)">
        </li>
      </ul>
      <h2>Relationships</h2>
      <ul>
        <li v-for="(fact, idx) in selected.relationships" :key="`rel-${idx}`">
          <div v-html="buildText(fact)"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import data from '../components/data'
import lodash from 'lodash'

export default {
  name: 'Home',
  components: {
  },
  data: function () {
    console.log('NODES')
    console.log(data.nodes)
    console.log('EDGES')
    console.log(data.edges)
    const eventHandlers = {
      'node:click': ({ node }) => {
        this.onClick('NODE', node)
      },
      'edge:click': ({ edge }) => {
        this.onClick('EDGE', edge)
      }
    }
    const v = {
      type: 'circle',
      radius: 15,
      color: node => {
        return node.color || 'gray'
      }
    }
    const configs = {
      node: {
        normal: v,
        hover: v
      }
    }
    const layouts = {
      nodes: data.nodes
    }
    console.log(layouts)
    return {
      nodes: data.nodes,
      edges: data.edges,
      selected: {
        name: '',
        facts: []
      },
      layouts,
      configs,
      eventHandlers: eventHandlers
    }
  },
  methods: {
    onClick: function (type, id) {
      if (type === 'NODE') {
        this.selected = data.nodes[id]
      } else if (type === 'EDGE') {
        this.selected = data.edges[id]
      }
    },
    buildText: function (text) {
      const names = Object.keys(this.nodes).sort((a, b) => -(a.length - b.length))
      names.forEach(name => {
        const easyName = lodash.startCase(name)
        text = text.replace(`@${name}`, `<b>${easyName}</b>`)
      })
      return `<div>${text}</div>`
    }
  }
}
</script>

<style scoped>
.graph {
  width: 800px;
  max-width: 100%;
  height: 600px;
  border: 1px solid #000;
}
</style>
