import data from './data'
import lodash from 'lodash'

const getPerson = person => {
  if (!data.people[person]) {
    data.people[person] = {
      facts: [],
      name: lodash.startCase(person),
      node: {},
      relationships: []
    }
    data.nodes[person] = data.people[person]
  }
  return data.people[person]
}
const getEdge = (a, b) => {
  const v = [a, b].sort().join('-')
  if (!data.relationships[v]) {
    const u = {
      source: a,
      target: b,
      facts: [],
      name: v
    }
    data.relationships[v] = u
    data.edges[v] = u
  }
  return data.relationships[v]
}

const peopleRegExp = /@([a-zA-Z]+)/g
const getPeople = text => {
  return text.match(peopleRegExp).map(v => v.substr(1))
}

export function so (text) {
  const people = getPeople(text)
  const peopleObjects = people.map(getPerson)
  if (people.length === 1) {
    peopleObjects[0].facts.push(text)
  } else {
    peopleObjects[0].relationships.push(text)
    for (let i = 1; i < peopleObjects.length; ++i) {
      const edge = getEdge(people[0], people[i])
      peopleObjects[i].relationships.push(text)
      edge.facts.push(text)
    }
  }
}

const COLOURS = {
  Person: 'green',
  Family: 'blue',
  Other: 'pink',
  Unseen: 'black'
}

export function design (val) {
  const thing = getPerson(val[0])
  thing.size = 22
  if (val[1]) {
    thing.color = COLOURS[val[1]]
  }
}

export function position () {
  const r = 240
  const vx = 2 * Math.PI / Object.values(data.people).length
  Object.values(data.people).forEach((v, idx) => {
    v.x = r * Math.cos(vx * idx)
    v.y = r * Math.sin(vx * idx)
  })
}
